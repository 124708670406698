export const versions = {
  version: "1.0.183",
  revision: "d8e319b7e",
  branch: "HEAD",
  gitInfo: {
    dirty: true,
    raw: "v1.0.183-4677-gd8e319b7e-dirty",
    hash: "gd8e319b7e",
    distance: 4677,
    tag: "v1.0.183",
    semver: {
      options: { loose: false, includePrerelease: false },
      loose: false,
      raw: "v1.0.183",
      major: 1,
      minor: 0,
      patch: 183,
      prerelease: [],
      build: [],
      version: "1.0.183"
    },
    suffix: "4677-gd8e319b7e-dirty",
    semverString: "1.0.183+4677.gd8e319b7e.dirty"
  }
};
